<template>
  <div class="root">
    <div class="bio">
      <div class="personal">
        <h1>Hello! My Name is</h1> <div class="name">Rohit Poduval</div>
        <h2>I work as a Product Manager at HotelKey, Inc</h2>
        <div class="social">
          <div class="linkedin">
            <a href="https://www.linkedin.com/in/rohit-poduval">
            <el-button size="large" style="background-color: #42b983; border-color: #42b983;" type="primary">LinkedIn</el-button></a>
          </div>    
          <div class="resume">
            <a target="_blank" style="text-decoration: none" href="https://drive.google.com/file/d/113sbYj9ngakOBecFcsPcGKbC37_uh-Ox/view?usp=sharing">
            <el-button size="large" style="background-color: #42b983; border-color: #42b983;" type="primary">Download CV</el-button></a>
          </div>
        </div>
      </div>
      <div class="professional">
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
          <el-tab-pane label="Work Experience" name="first"> <Work/> </el-tab-pane>
          <el-tab-pane label="Skills" name="second"><Skills/></el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <div class="projects">
      <Cases/>
    </div>
  </div>


</template>

<script>
import Work from '../components/Work.vue'
import Skills from '../components/Skills.vue'
import Cases from '../components/Cases.vue'

export default {
  name: 'Home',
  components: { Work, Skills, Cases},
  props: {
    msg: String
  },

  data(){
    return{
      activeName: 'first'
    }
  }

}
</script>

<style scoped>

body{
  margin: 0%;
  padding: 0%;
}

.root{
  margin: 0% 15%;
}

.bio{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-bottom: 20px;
}

.personal{
  padding-bottom: 20px;
}

.social{
  display: flex;
  justify-content: center;
}

.linkedin{
  padding: 0px 10px;
}

.professional{
  padding: 20px 0 ;
}
.name{
  color: #42b983;
  font-size: 2em;
  font-weight: bold;
  display: block;
}

.projects{
    margin: 10px 0px;
}



</style>
