<template>
  <div class="content">
    <div class="main-header">
      <img class="icon" src="../assets/back.png" @click="gotoHome()" />
      <h2>{{ data[0].header }}</h2>
    </div>
    <div class="image">
      <!-- <img class="cover" src="../assets/att.jpeg"> -->
    </div>
    <div class="section" v-for="item in data[0].sub_sections" :key="item.order">
      <div class="title">{{ item.header }}</div>
      <div class="main">{{ item.main_content }}</div>
    </div>
  </div>
</template>

<script>
import cases from "@/data.json";
export default {
  name: "App",

  data() {
    return {
      data: cases,
    };
  },
  methods: {
    gotoHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style>
.body {
  margin: 0px;
  padding: 0px;
}

.content {
  text-align: justify;
  padding: 10px 0px;
  margin: 0 10%;
}

.icon {
  height: 40px;
  width: 40px;
  padding: 0px 10px 0 0;
}

.main-header {
  padding: 20px 0px;
  display: flex;
  align-items: center;
}

.cover {
  height: 450px;
  width: 100%;
  border-radius: 5px;
}

.section {
  padding: 20px 0px;
}

.title {
  font-size: 30px;
  padding: 10px 0px;
}

.main {
  font-size: 20px;
  line-height: 1.5;
}
</style>
