<template>
    
    <div class="case-studies">
        <div class="case-header">
            <h3>My UX Case Studies</h3>
        </div>
        <div class="all-cases">
            <div class="case" v-for="item in cases" :key="item.title">
                <el-card :body-style="{ padding: '20px' }">
                        <img :src="item.cover" class="case-image"/>
                        <div class="case-title">
                            <span>{{ item.header }}</span>
                        </div>
                        <div class="bottom">
                                <el-button type="primary" size="large" @click="gotoCase(item.url)" class="button">View More</el-button>
                            </div>
                </el-card>
            </div>
        </div>
    </div>


</template>

<script>
import cases from '@/data.json'

export default {
    name: 'Cases',

    data(){
      return{
        loading: false,
        cases: cases
        }
    },

    methods : {

        gotoCase(url){
            this.$router.push(url)
        }
        
    }

}    

</script>

<style scoped>

.all-cases{
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    flex-wrap: wrap;
}

.case{
    padding: 20px 0px
}

.case-title{
    text-align: left;
    font-weight: 800;
    font-size: 20px;
    padding-bottom: 20px;

}

.case-image{
    height: 250px;
    width: 100%;
    padding-bottom: 20px;
    margin: 0px;
}

.bottom {
  display: flex;
  flex-direction: row-reverse; 
}

.case{
    width: 400px;
}
.el-card {
    width: 100%;
}




</style>