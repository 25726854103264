import { createApp } from "vue";
import ElementPlus from "element-plus";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import "element-plus/dist/index.css";
import { createRouter, createWebHashHistory } from "vue-router";
import { createPinia } from "pinia";
import App from "./App.vue";
import About from "./views/About.vue";
import Att from "./components/Att.vue";
import Rent from "./components/Rent.vue";
import Analytics from "./components/Analytics.vue";
import VueApexCharts from "vue3-apexcharts";

const routes = [
  { path: "/", component: About, name: "about" },
  { path: "/att", component: Att, name: "att" },
  { path: "/rent", component: Rent, name: "rent" },
  { path: "/analytics", component: Analytics, name: "Analytics" },
];

const router = createRouter({
  history: createWebHashHistory(),
  mode: "hash",
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

const app = createApp(App);
app.use(createPinia());
app.use(router);
app.use(ElementPlus);
app.use(ElementPlusIconsVue);
app.use(VueApexCharts);
app.mount("#app");
