<template>
    <div class="button-container">
      <!-- Start Checkout Button -->
      <el-button type="primary" @click="triggerStartCheckoutEvent">
        Start Checkout
      </el-button>
  
      <!-- Purchase Button -->
      <el-button type="success" @click="triggerPurchaseEvent">
        Purchase
      </el-button>
  
      <!-- Book Intent Button -->
      <el-button type="warning" @click="triggerBookIntentEvent">
        Book Intent
      </el-button>
  
      <!-- Custom Event Button (for extra actions) -->
      <el-button type="info" @click="triggerCustomEvent">
        Custom Action
      </el-button>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      // Google Analytics 4: Start Checkout Event
      triggerStartCheckoutEvent() {
        window.gtag('event', 'start_checkout', {
          currency: 'USD',
          value: 49.99,
          items: [
            {
              item_name: 'Product A',
              item_id: 'SKU123',
              price: 49.99,
              quantity: 1
            }
          ]
        });
      },
      
      // Google Analytics 4: Purchase Event
      triggerPurchaseEvent() {
        window.gtag('event', 'purchase', {
          transaction_id: 'T12345',
          affiliation: 'Online Store',
          value: 59.99,
          currency: 'USD',
          tax: 5.99,
          shipping: 7.99,
          items: [
            {
              item_name: 'Product A',
              item_id: 'SKU123',
              price: 49.99,
              quantity: 1
            }
          ]
        });
      },
      
      // Google Analytics 4: Book Intent Event
      triggerBookIntentEvent() {
        window.gtag('event', 'book_intent', {
          currency: 'USD',
          value: 29.99,
          items: [
            {
              item_name: 'Room A',
              item_id: 'ROOM123',
              price: 29.99,
              quantity: 1
            }
          ]
        });
      },
      
      // Custom Event (You can define any other event here)
      triggerCustomEvent() {
        window.gtag('event', 'custom_action', {
          action_name: 'CustomAction',
          value: 19.99,
          currency: 'USD'
        });
      }
    }
  };
  </script>
  
  <style scoped>
  .button-container {
    display: flex;
    gap: 10px;
    justify-content: center;
    padding: 20px;
  }
  </style>
  